<template>
<div>
  <p class="mt-2">
    <b-checkbox
      :value="currentNotificationPreferences.account"
      @input="onNotifyAccount"
      :tabindex="tabindex(1)"
    >
      Subscribe to Account Notifications
    </b-checkbox>
  </p>
  <p class="mt-2">
    <b-checkbox
      :value="currentNotificationPreferences.event"
      @input="onNotifyEvent"
      :tabindex="tabindex(2)"
    >
      Subscribe to Event Notifications
    </b-checkbox>
  </p>
  <p class="mt-2">
    <b-checkbox
      :value="currentNotificationPreferences.challenge"
      @input="onNotifyChallenge"
      :tabindex="tabindex(3)"
    >
      Subscribe to Challenge Notifications
    </b-checkbox>
  </p>
  <p class="mt-2">
    <b-checkbox
      :value="currentNotificationPreferences.discussion"
      @input="onNotifyDiscussion"
      :tabindex="tabindex(4)"
    >
      Subscribe to Discussion Notifications
    </b-checkbox>
  </p>
</div>
</template>

<script>
export default {
  name: "OptOutEdit",
  props: {
    tabpriority: {
      type: Number,
      required: false
    },
    currentNotificationPreferences: {
      type: Object,
      required: true
    }
  },
  methods: {
    tabindex(i) {
      if (!this.tabpriority) {
        return i;
      }
      return this.tabpriority * 10 + i;
    },

    onNotifyAccount(val) {
      this.$emit("notifyAccountChanged", val);
    },
    onNotifyChallenge(val) {
      this.$emit("notifyChallengeChanged", val);
    },
    onNotifyEvent(val) {
      this.$emit("notifyEventChanged", val);
    },
    onNotifyDiscussion(val) {
      this.$emit("notifyDiscussionChanged", val);
    }
  }
};
</script>
