<template>
  <b-select v-bind="$attrs" :value="value" @input="onInput">
    <option v-for="cc in countries" :key="cc.abbrev" :value="cc.abbrev">
      {{ cc.name }}
    </option>
  </b-select>
</template>

<script>
import { LIST_COUNTRIES } from "@/models/countries/operations.gql";

export default {
  name: "CountrySelect",
  components: {},
  props: {
    value: {
      type: String,
      default: () => "US"
    }
  },
  data() {
    return {
      countries: []
    };
  },
  apollo: {
    countries: {
      query: LIST_COUNTRIES
    }
  },
  methods: {
    onInput(value) {
      this.$emit("input", value);
    }
  }
};
</script>
