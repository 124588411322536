<template>
  <section>
    <div :class="`${suwDesktop ? 'mx-6 px-6' : ''}`">
      <h2 class="mb-3">Your Profile</h2>

      <div class="is-flex is-justify-content-space-between is-align-items-center my-1">
        <div class="level-item has-text-weight-bold">
          Email (forces logout and email verification)
        </div>
      </div>
      <div>
        <b-input v-model.trim="$v.email.$model" type="email" required />
        <p class="has-text-danger-dark is-size-7" v-if="!$v.email.required">Email is required.</p>
      </div>

      <div class="is-flex is-justify-content-space-between is-align-items-center my-1">
        <div class="has-text-weight-bold form__label">
          Username
        </div>
      </div>
      <div>
        <b-input class="form__input" v-model.trim="$v.username.$model" type="text" required />
        <p class="has-text-danger-dark is-size-7" v-if="!$v.username.required">Username is required.</p>
        <p class="has-text-danger-dark is-size-7" v-if="!$v.username.maxLength">Username must be at least {{$v.username.$params.minLength.max}} characters.</p>
        <p class="has-text-danger-dark is-size-7" v-if="!$v.username.maxLength">Username may not exceed {{$v.username.$params.maxLength.max}} characters.</p>
        <p class="has-text-danger-dark is-size-7" v-if="!$v.username.mustContainValidCharacters">Username must be lowercase, contain only letters, numbers, and the . _ - characters.</p>
        <p class="has-text-danger-dark is-size-7" v-if="!$v.username.$invalid && !available">Username is already taken.</p>
        <p class="has-text-success is-size-7" v-if="!$v.username.$invalid && available">Username is available.</p>
      </div>

      <div class="is-flex is-justify-content-space-between is-align-items-center my-1">
        <div class="has-text-weight-bold form__label">
          First Name
        </div>
      </div>
      <div>
        <b-input class="form__input" v-model.trim="$v.first_name.$model" type="text" required />
        <p class="has-text-danger-dark is-size-7" v-if="!$v.first_name.required">First Name is required.</p>
        <p class="has-text-danger-dark is-size-7" v-if="!$v.first_name.maxLength">First Name may not exceed {{$v.first_name.$params.maxLength.max}} characters.</p>
      </div>

      <div class="is-flex is-justify-content-space-between is-align-items-center my-1">
        <div class="has-text-weight-bold form__label">
          Last Name
        </div>
      </div>
      <div>
        <b-input class="form__input" v-model.trim="$v.last_name.$model" type="text" required  />
        <p class="has-text-danger-dark is-size-7" v-if="!$v.last_name.required">Last Name is required.</p>
        <p class="has-text-danger-dark is-size-7" v-if="!$v.last_name.maxLength">Last Name may not exceed {{$v.last_name.$params.maxLength.max}} characters.</p>
      </div>

      <p class="mt-2">
        <b-checkbox v-model="isLastNameShown" :value="isLastNameShown">
          Show My Full Last Name
        </b-checkbox>
      </p>

      <div class="is-flex is-justify-content-space-between is-align-items-center my-1">
        <div class="level-item has-text-weight-bold">
          About Me
        </div>
      </div>
      <div>
        <b-input v-model.trim="description" type="textarea"/>
      </div>

      <div class="is-flex is-justify-content-space-between is-align-items-center my-1">
        <div class="has-text-weight-bold form__label">
          Languages
        </div>
      </div>
      <div>
        <b-input v-model.trim="languages" class="form__input" type="text" />
      </div>

      <div class="is-flex is-justify-content-space-between is-align-items-center my-1">
        <div class="has-text-weight-bold">
          Country
        </div>
      </div>
      <div>
        <CountrySelect v-model="country_abbrev" expanded />
      </div>

      <div class="is-flex is-justify-content-space-between is-align-items-center my-1">
        <div class="has-text-weight-bold form__label">
          Postal Code
        </div>
      </div>
      <div>
        <b-input class="form__input" v-model.trim="$v.postal_code.$model" type="text" required />
        <p class="has-text-danger-dark is-size-7" v-if="!$v.postal_code.minLength">Postal Code must contain a minimum of {{$v.postal_code.$params.minLength.max}} characters.</p>
        <p class="has-text-danger-dark is-size-7" v-if="!$v.postal_code.maxLength">Postal Code may not exceed {{$v.postal_code.$params.maxLength.max}} characters.</p>
        <p class="has-text-danger-dark is-size-7" v-if="!$v.postal_code.mustContainValidCharacters">Postal Code may contain only letters, numbers, and the . _ - characters.</p>
      </div>

      <div class="is-flex is-justify-content-space-between is-align-items-center my-1">
        <div class="has-text-weight-bold form__label">
          Avatar Color
        </div>
      </div>
      <div>
        <b-colorpicker v-model="avatar_color" :open-on-focus="true" expanded />
      </div>

      <p v-if="$auth.isAdmin" class="mt-2">
        <b-checkbox v-model="showCMSDrafts" :value="showCMSDrafts" @input="onShowCMSDrafts">
          Show My Draft (unpublished) Articles
        </b-checkbox>
      </p>

      <OptOutEdit 
        class="my-1" 
        :tabpriority="9" 
        :currentNotificationPreferences="currentNotificationPreferences"
        @notifyAccountChanged="handleNotifyAccountChanged"
        @notifyEventChanged="handleNotifyEventChanged"
        @notifyChallengeChanged="handleNotifyChallengeChanged"
        @notifyDiscussionChanged="handleNotifyDiscussionChanged"
      />
      <b-button
        native-type="submit"
        type="is-info is-inverted"
        class="mr-1 my-1"
        label="Confirm Changes"
        :disabled="$v.$invalid"
        @click="confirmChanges"
      />

    </div>
  </section>
</template>

<script>
import { GET_USER_BY_USERNAME, USER_OPTOUTS, USER_OPTOUT_INSERT, USER_OPTOUT_DELETE } from "@/models/users/operations.gql";
import { UpdateUserProfile } from "@/models/actions/operations.gql";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import CountrySelect from "@/components/common/CountrySelect.vue";
import OptOutEdit from "@/components/dashboard/OptOutEdit.vue";
import { helpers } from "vuelidate/lib/validators";
import {
  EMPTY_FIELD,
  NOT_EXIST,
  INVALID_FORMAT,
  UPDATE_FAILED,
  DEFAULT_FALLBACK,
  getErrorMessage,
  fallbackErrorMessage
} from "@/backendException.js";

const mustContainValidCharacters = helpers.regex("valid_characters", /^[a-zA-Z0-9.\-_()]+$/);

export default {
  name: "ProfileTab",
  components: {
    OptOutEdit,
    CountrySelect
  },
  data() {
    return {
      showCMSDrafts: false,
      email: this.$auth.user.email,
      username: this.$auth.user.username,
      available: false,
      first_name: this.$auth.user.first_name,
      last_name: this.$auth.user.last_name,
      isLastNameShown: !this.$auth.user.is_last_name_hidden,
      description: this.$auth.user.description,
      country_abbrev: this.$auth.user.country_abbrev,
      postal_code: this.$auth.user.postal_code,
      avatar_color: this.$auth.user.avatar_color,
      languages: this.$auth.user.languages,
      currentNotificationPreferences: {
        account: true,
        event: true,
        challenge: true,
        discussion: true
      },
      newNotificationPreferences: {
        account: null,
        event: null,
        challenge: null,
        discussion: null
      },
      categoryAccount: ["host_promotion"],
      categoryEvent: [
        "series_created",
        "series_update_member",
        "series_member_event_reminder",
        "event_update_member",
        "series_member_joining",
        "series_member_leaving",
        "event_user_rsvp",
        "event_user_unrsvp"
      ],
      categoryDiscussion: [
        "new_topic_dm",
        "new_topic_series",
        "new_post_dm",
        "new_post_post",
        "new_post_submission"
      ],
      categoryChallenge: ["prompt_is_open_challenge_users", "challenge_accept_challenge_users"],
      validationReasonMap: new Map([
        [EMPTY_FIELD, "Required fields must be filled out."],
        [NOT_EXIST, "User does not exist."],
        [INVALID_FORMAT, "Email format is invalid."],
        [DEFAULT_FALLBACK, fallbackErrorMessage]
      ]),
      executionReasonMap: new Map([
        [UPDATE_FAILED, "Update failed. Please try again."],
        [DEFAULT_FALLBACK, fallbackErrorMessage]
      ])
    };
  },
  computed: {
    eventNotificationPreferenceChanged() {
      return this.newNotificationPreferences.event !== null && this.currentNotificationPreferences.event !== this.newNotificationPreferences.event;
    },
    challengeNotificationPreferenceChanged() {
      return this.newNotificationPreferences.challenge !== null && this.currentNotificationPreferences.challenge !== this.newNotificationPreferences.challenge;
    },
    discussionNotificationPreferenceChanged() {
      return this.newNotificationPreferences.discussion !== null && this.currentNotificationPreferences.discussion !== this.newNotificationPreferences.discussion;
    },
    accountNotificationPreferenceChanged() {
      return this.newNotificationPreferences.account !== null && this.currentNotificationPreferences.account !== this.newNotificationPreferences.account;
    }
  },
  watch: {
    showCMSDrafts(val) {
      localStorage.showCMSDrafts = JSON.stringify(val);
    }
  },
  apollo: {
    available: {
      query: GET_USER_BY_USERNAME,
      debounce: 500,
      error(err) {
        this.error = JSON.stringify(err.message);
        this.username_field_message = "service issue";
        this.available = false;
      },
      update(data) {
        this.error = "";
        if (data.users) {
          if (data.users.length === 0) {
            return true;
          } else if (data.users.length === 1) {
            if (data.users[0].id == this.$auth.user.id) {
              this.username = data.users[0].username;
              return true; // it's us, we're just re-using the username we already have
            }
          }
        }
        return false;
      },
      variables() {
        return {
          username: this.username
        };
      },
      // don't check with server unless we have enough to check
      skip() {
        return this.loading || this.$v.username.$invalid;
      }
    },
    optouts: {
      query: USER_OPTOUTS,
      variables() {
        return {
          user_id: this.$auth.user.id
        };
      },
      update({ notifications_user_optouts }) {
        if (notifications_user_optouts) {
          notifications_user_optouts.forEach(o => {
            var i = this.categoryAccount.indexOf(o.email_template_value);
            if (i > -1) {
              this.categoryAccount.splice(i, 1);
            }
            i = this.categoryEvent.indexOf(o.email_template_value);
            if (i > -1) {
              this.categoryEvent.splice(i, 1);
            }
            i = this.categoryDiscussion.indexOf(o.email_template_value);
            if (i > -1) {
              this.categoryDiscussion.splice(i, 1);
            }
            i = this.categoryChallenge.indexOf(o.email_template_value);
            if (i > -1) {
              this.categoryChallenge.splice(i, 1);
            }
          });

          //only toggle a category to 'false' if ALL email_templates for that category are present
          if (this.categoryAccount.length == 0) {
            this.currentNotificationPreferences.account = false;
          }
          if (this.categoryEvent.length == 0) {
            this.currentNotificationPreferences.event = false;
          }
          if (this.categoryDiscussion.length == 0) {
            this.currentNotificationPreferences.discussion = false;
          }
          if (this.categoryChallenge.length == 0) {
            this.currentNotificationPreferences.challenge = false;
          }
        }
        return null;
      }
    }
  },
  mounted() {
    this.showCMSDrafts = !!localStorage.showCMSDrafts && JSON.parse(localStorage.showCMSDrafts);
  },
  validations: {
    email: {
      required
    },
    username: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(20),
      mustContainValidCharacters
    },
    first_name: {
      required,
      maxLength: maxLength(40)
    },
    last_name: {
      required,
      maxLength: maxLength(40)
    },
    postal_code: {
      minLength: minLength(4),
      maxLength: maxLength(10),
      mustContainValidCharacters
    }
  },
  methods: {
    onShowCMSDrafts(e) {
      //this.$log.info("what I'm about to put in localstorage", e);
      this.showCMSDrafts = e;
    },
    async confirmChanges() {
      try {
        const response = await this.$apollo.mutate({
          mutation: UpdateUserProfile,
          variables: {
            userId: this.$auth.user.id,
            email: this.email,
            userName: this.username,
            firstName: this.first_name,
            lastName: this.last_name,
            isLastNameHidden: !this.isLastNameShown,
            description: this.description,
            countryAbbrev: this.country_abbrev,
            postalCode: this.postal_code,
            avatarColor: this.avatar_color.toString(),
            languages: this.languages,
            unitSystem: "metric" //for now, until it's used and until we have it in the UI
          }
        });
        const errors = response.data.result.errors;
        if (errors?.length > 0) {
          this.$buefy.snackbar.open({
            queue: false,
            message: getErrorMessage(errors, this.validationReasonMap, this.executionReasonMap),
            position: "is-top",
            type: "is-danger"
          });
        } else {
          this.$buefy.snackbar.open({
            message: `Profile Updated`,
            type: "is-success"
          });
          var emailChanged = this.$auth.user.email !== this.email;
          if (emailChanged) {
            this.$buefy.snackbar.open({
              message: `Email verification required. You will now be logged out`,
              type: "is-success",
              position: "is-top"
            });
            this.$auth.logout({ redirectLocation: { name: "home" } });
          } else {
            this.$auth.refreshAccess(); // causes a reload of login via the refresh token (picks up new profile data in $auth.user)
          }
        }
      } catch (error) {
        this.$buefy.toast.open({
          message: fallbackErrorMessage,
          type: "is-danger"
        });
      }
      if (this.eventNotificationPreferenceChanged) {
        this.updateNotificationPreference(this.newNotificationPreferences.event, "series_created");
        this.updateNotificationPreference(this.newNotificationPreferences.event, "series_update_member");
        this.updateNotificationPreference(this.newNotificationPreferences.event, "series_member_event_reminder");
        this.updateNotificationPreference(this.newNotificationPreferences.event, "event_update_member");
        this.updateNotificationPreference(this.newNotificationPreferences.event, "series_member_joining");
        this.updateNotificationPreference(this.newNotificationPreferences.event, "series_member_leaving");
        this.updateNotificationPreference(this.newNotificationPreferences.event, "event_user_rsvp");
        this.updateNotificationPreference(this.newNotificationPreferences.event, "event_user_unrsvp");
        this.currentNotificationPreferences.event = this.newNotificationPreferences.event;
        this.newNotificationPreferences.event = null;
        this.$buefy.snackbar.open({
          message: `Notification Preference Updated`,
          type: "is-success"
        });
      }
      if (this.challengeNotificationPreferenceChanged) {
        this.updateNotificationPreference(this.newNotificationPreferences.challenge, "prompt_is_open_challenge_users");
        this.updateNotificationPreference(this.newNotificationPreferences.challenge, "challenge_accept_challenge_users");
        this.currentNotificationPreferences.challenge = this.newNotificationPreferences.challenge;
        this.newNotificationPreferences.challenge = null;
        this.$buefy.snackbar.open({
          message: `Notification Preference Updated`,
          type: "is-success"
        });
      }
      if (this.discussionNotificationPreferenceChanged) {
        this.updateNotificationPreference(this.newNotificationPreferences.discussion, "new_topic_dm");
        this.updateNotificationPreference(this.newNotificationPreferences.discussion, "new_topic_series");
        this.updateNotificationPreference(this.newNotificationPreferences.discussion, "new_post_dm");
        this.updateNotificationPreference(this.newNotificationPreferences.discussion, "new_post_post");
        this.updateNotificationPreference(this.newNotificationPreferences.discussion, "new_post_submission");
        this.currentNotificationPreferences.discussion = this.newNotificationPreferences.discussion;
        this.newNotificationPreferences.discussion = null;
        this.$buefy.snackbar.open({
          message: `Notification Preference Updated`,
          type: "is-success"
        });
      }
      if (this.accountNotificationPreferenceChanged) {
        this.updateNotificationPreference(this.newNotificationPreferences.account, "host_promotion");
        this.currentNotificationPreferences.account = this.newNotificationPreferences.account;
        this.newNotificationPreferences.account = null;
        this.$buefy.snackbar.open({
          message: `Notification Preference Updated`,
          type: "is-success"
        });
      }
    },
    updateNotificationPreference(optinorout, email_template_value) {
      const variables = {
        user_id: this.$auth.user.id,
        email_template_value
      };

      this.$apollo.mutate({
        mutation: optinorout == false ? USER_OPTOUT_INSERT : USER_OPTOUT_DELETE,
        variables
      });
    },
    handleNotifyAccountChanged(val) {
      this.newNotificationPreferences.account = val;
    },
    handleNotifyEventChanged(val) {
      this.newNotificationPreferences.event = val;
    },
    handleNotifyChallengeChanged(val) {
      this.newNotificationPreferences.challenge = val;
    },
    handleNotifyDiscussionChanged(val) {
      this.newNotificationPreferences.discussion = val;
    }
  }
};
</script>
